import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const usePatientStore = create(
	persist(
		(set, get) => ({
			patient: null,
			attendances: [],
			setPatient: (data) => {
				set({
					patient: data.patient || null,
					attendances: data.attendances || [],
				});
			},
			clearPatientData: () => {
				set({ patient: null, attendances: [] });
			},
			addAttendances: (attendance) => {
				const updatedAttendances = [...get().attendances, attendance];
				set({ attendances: updatedAttendances });
			},
			removeAttendance: (attendanceId) => {
				const updatedAttendances = get().attendances.filter((attendance) => attendance.id !== attendanceId);
				set({ attendances: updatedAttendances });
			},
			clearAttendances: () => {
				set({ attendances: [] });
			},
		}),
		{
			name: 'patient',
			partialize: (state) => ({
				patient: state.patient,
				attendances: state.attendances,
			}),
		}
	)
);

export default usePatientStore;
