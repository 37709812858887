import React, { useCallback, useState, useMemo } from 'react';
import { showConfirmationAlert } from '@amigoapp/doca-react';
import { showErrorToast, showSuccessToast } from '@amigoapp/doca-react';
import { cancelAttendance } from '../../processes/attendance';
import { useHistory } from 'react-router-dom';
import { useCompanyStore, usePatientStore } from '../../stores';
import { NavigationUtils, WindowDimensionsUtils, DateUtils } from '../../utils';
import PatientNextAttendances from './PatientNextAttendances';

const footerPlusHeaderHeight = 344;

function PatientNextAttendancesContainer() {
	const { companySettings: { settings } } = useCompanyStore();
	const { patient, attendances, removeAttendance } = usePatientStore();
	const [loading, setLoading] = useState(false);
	const dimensions = WindowDimensionsUtils.useWindowDimensions();
	const listMaxHeight = useMemo(() => (!dimensions?.height ? 300 : dimensions?.height - footerPlusHeaderHeight), [dimensions?.height]);
	const isMobile = dimensions?.width < 600;
	const history = useHistory();

	const handleScheduleAttendance = useCallback(() => {
		NavigationUtils.navigate(history, '/select-event-group-payment-method');
	}, [history]);

	const handleListAllAttendances = useCallback(() => {
		NavigationUtils.goBackHome(history);
	}, [history]);

	const handleEditAttendancePress = useCallback(
		(attendance) => {
			NavigationUtils.navigate(history, `/update-timegrid/${attendance?.external_pages_token}`);
		},
		[history]
	);

	const handleCancelAttendancePress = useCallback(
		async (attendance) => {
			const result = await showConfirmationAlert({
				title: 'Você deseja cancelar o atendimento?',
				text: 'O cancelamento do seu agendamento não poderá ser desfeito e o horário será liberado para novos agendamentos.',
				icon: 'circle-exclamation',
				iconColor: 'danger',
				cancelButtonText: 'Manter agendamento',
				confirmButtonText: 'Cancelar agendamento',
				confirmButtonVariant: 'danger',
				width: isMobile ? '390px' : '100%',
			});

			if (result.isConfirmed) {
				setLoading(true);
				const { error } = await cancelAttendance({ token: attendance?.external_pages_token });
				if (error) {
					showErrorToast('Não foi possível cancelar o agendamento.');
				} else {
					removeAttendance(attendance.id);
					showSuccessToast('Agendamento cancelado com sucesso.');
				}
				setLoading(false);
			}
		},
		[setLoading, isMobile, removeAttendance]
	);

	const calculateRemainingDaysInfo = (attendanceDate) => {
		const today = DateUtils.now().startOf('day');
		const appointmentDate = DateUtils.getDateInstance(attendanceDate).startOf('day');
		const daysRemaining = appointmentDate.diff(today, 'days');

		if (DateUtils.isToday(appointmentDate)) {
			return { labelText: 'Hoje', labelColor: '#3CB224', labelTextColor: 'white' };
		}

		if (appointmentDate.diff(today, 'days') === 1) {
			return { labelText: 'Amanhã', labelColor: '#FFCC00', labelTextColor: '#14191E' };
		}

		return { labelText: `Faltam ${daysRemaining} dias`, labelColor: '#0088FF', labelTextColor: 'white' };
	};

	return (
		<PatientNextAttendances
			variant={settings?.theme?.name}
			handleScheduleAttendance={handleScheduleAttendance}
			handleListAllAttendances={handleListAllAttendances}
			patient={patient}
			attendances={attendances?.map((attendance) => ({
				...attendance,
				remainingDaysInfo: calculateRemainingDaysInfo(attendance.start_date)
			}))}
			listMaxHeight={listMaxHeight}
			handleEditAttendancePress={handleEditAttendancePress}
			handleCancelAttendancePress={handleCancelAttendancePress}
			loading={loading}
		/>
	);
}

export default PatientNextAttendancesContainer;
