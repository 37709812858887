import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from "formik";

import PatientForm from './PatientForm';
import { create } from '../../processes/patient';
import { createAttendance } from '../../processes/attendance';
import { useCompanyStore, usePatientStore, useAnswersStore } from '../../stores';
import { DateUtils, NavigationUtils, FormatterUtils } from '../../utils';
import { showErrorToast } from '@amigoapp/doca-react';

function PatientFormContainer() {
	const { companySettings: { settings } } = useCompanyStore();
	const { patient } = usePatientStore();
	const { answers } = useAnswersStore();
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

	const validate = values => {
		const errors = {};

		if (!values.name) {
			errors.name = 'O nome é um campo obrigatório.';
		}

		if (!values.cpf && !values.is_foreigner) {
			errors.cpf = "O cpf é um campo obrigatório.";
		}

		if (!values.gender) {
			errors.gender = 'O gênero é um campo obrigatório.'
		}

		if (!values.born) {
			errors.born = 'A data de nascimento é um campo obrigatório.'
		}

		if (!values.contact_cellphone) {
			errors.contact_cellphone = 'O telefone é um campo obrigatório.'
		}

		if (!values.email) {
			errors.email = 'O email é um campo obrigatório.'
		}

		if (!values.terms_of_use?.includes('on')) {
			errors.terms_of_use = 'É necessário aceitar os termos de uso.'
		}

		return errors;
	};

	const scheduleAttendance = useCallback(async (attendanceData) => {
		const { data, error } = await createAttendance(attendanceData);

		if (error) {
			showErrorToast('Algo de errado aconteceu ao criar o agendamento.');
		} else {
			NavigationUtils.navigate(history, `/scheduled-succesfully?token=${data.external_pages_token}`);
		}
	}, [history]);

	const handleSubmitPress = useCallback(async values => {
		setLoading(true);

		const { data, error } = await create({
			...values,
			cpf: values.cpf && FormatterUtils.clearMask(values.cpf),
			born: DateUtils.formatDate(values.born, 'YYYY-MM-DD', 'DD/MM/YYYY'),
			contact_cellphone: FormatterUtils.clearMask(values.contact_cellphone),
			is_foreigner: !!values.is_foreigner?.includes('on'),
			terms_of_use: !!values.terms_of_use?.includes('on')
		});

		if (error) {
			showErrorToast('Não foi possível criar o paciente.');
			setLoading(false);
		} else {

			await scheduleAttendance({
				event_id: answers.event?.id,
				place_id: answers.place?.id,
				patient_id: data.id,
				insurance_id: answers.insurance?.id,
				start_date: answers.start_date,
				end_date: answers.end_date,
				user_id: answers.slot.user_id,
			});
			setLoading(false);
		}

	}, [setLoading, scheduleAttendance, answers]);

	const {
		handleSubmit,
		handleChange,
		isValid,
		setFieldValue,
		values
	} = useFormik({
		initialValues: {
			born: patient?.born || '',
			name: patient?.name || '',
			is_foreigner: patient?.is_foreigner,
			cpf: patient?.cpf ? FormatterUtils.formatCpf(patient.cpf) : '',
			gender: patient?.gender,
			contact_cellphone: patient?.contact_cellphone ? FormatterUtils.formatPhone(patient.contact_cellphone) : '',
			email: patient?.email,
			terms_of_use: patient?.terms_of_use
		},
		validate,
		validateOnMount: true,
		onSubmit: handleSubmitPress
	});

	const onChangeWithMask = useCallback((event, mask) => {
		if (event.target.value) {
			event.target.value = mask(event.target.value);
		}

		return handleChange(event)
	}, [handleChange]);

	const handleNavigateBack = useCallback(() => {
		NavigationUtils.navigate(history, '/select-timegrid');
	}, [history]);

	return (
		<PatientForm
			values={values}
			loading={loading}
			onChange={handleChange}
			isValid={isValid}
			variant={settings?.theme?.name}
			onSubmitClick={handleSubmit}
			setFieldValue={setFieldValue}
			onChangeWithMask={onChangeWithMask}
			handleNavigateBack={handleNavigateBack}
		/>
	);
}

export default PatientFormContainer;
