import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useCompanyStore, useAnswersStore } from '../../stores';

import { NavigationUtils, Utils } from '../../utils';

import { findScheduleSettingsBySlug, findSettingsBySlug } from '../../processes/company';

import Home from './Home';

function HomeContainer() {
	const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState('ABOUT');
	const [openedModal, setOpenedModal] = useState(false);
	const [galleryImageIndex, setGalleryImageIndex] = useState(false);

	const history = useHistory();

	const { companySettings: { settings, scheduleSettings }, setCompanySettings, clearCompanySettings } = useCompanyStore();
	const { clearAnswers } = useAnswersStore();

	const slug = Utils.getHostSubdomain();

	const fetchCompanyInfo = useCallback(async () => {
		const filter = {
			slug
		};

		if (!filter.slug) {
			return;
		}

		clearCompanySettings();
		clearAnswers();

		setLoading(true);

		const [companySettings, companySchedulerSettings] = await Promise.all([findSettingsBySlug(filter), findScheduleSettingsBySlug(filter)]);

		if (companySettings.data?.name) {
			document.title = companySettings.data.name;
		}

		setCompanySettings({
			settings: companySettings?.data,
			scheduleSettings: companySchedulerSettings?.data
		});

		localStorage.setItem('token', companySettings?.data?.token);
		localStorage.setItem('slug', slug);

		setLoading(false);
	}, [slug, setCompanySettings, clearCompanySettings, clearAnswers]);

	useEffect(() => {
		fetchCompanyInfo();
	}, [slug, fetchCompanyInfo]);

	const handleClickGalleryImages = useCallback((index) => {
		setOpenedModal(true);
		setGalleryImageIndex(index);
	}, []);

	const handleChangeTab = useCallback((tab) => setActiveTab(tab), []);

	const handleSchedulePress = useCallback(() => {
		NavigationUtils.navigate(history, '/patient-exists');
	}, [history]);

	return (
		<Home
			loading={loading}
			openedModal={openedModal}
			setOpenedModal={setOpenedModal}
			galleryImageIndex={galleryImageIndex}
			settings={settings}
			scheduleSettings={scheduleSettings}
			activeTab={activeTab}
			variant={settings?.theme?.name}
			handleSchedulePress={handleSchedulePress}
			handleChangeTab={handleChangeTab}
			handleClickGalleryImages={handleClickGalleryImages}
		/>
	);
}

export default HomeContainer;
