import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCompanyStore, useAnswersStore, usePatientStore } from '../../stores';
import { DateUtils, NavigationUtils, WindowDimensionsUtils } from '../../utils';
import { list } from '../../processes/calendar';
import { listEvents } from '../../processes/agenda-event';
import { createAttendance } from '../../processes/attendance';
import SelectTimegridForm from './SelectTimegridForm';
import moment from 'moment';
import { showErrorToast } from '@amigoapp/doca-react';

const footerPlusHeaderHeight = 344;

function SelectTimegridFormContainer() {
	const history = useHistory();
	const { companySettings: { settings, scheduleSettings } } = useCompanyStore();
	const { answers, setAnswer, clearEventSlotPlaceAndUser, appendAnswers } = useAnswersStore();
	const { patient } = usePatientStore();

	const dimensions = WindowDimensionsUtils.useWindowDimensions();

	const [loading, setLoading] = useState(true);
	const [loadingTimegrid, setLoadingTimegrid] = useState(false);
	const [slotsByDate, setSlotsByDate] = useState([]);
	const [enabledDates, setEnabledDates] = useState([]);
	const [currentSlotsByUser, setCurrentSlotsByUser] = useState([]);
	const [events, setEvents] = useState([]);
	const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));

	const listMaxHeight = useMemo(() => (!dimensions?.height ? 300 : dimensions?.height - footerPlusHeaderHeight), [dimensions?.height]);

	const getEvents = useCallback(async () => {
		setLoading(true);

		const filter = {
			insurance_id: answers?.insurance?.id,
			scheduler_event_group_id: answers?.event_group?.id
		};

		if (!filter.scheduler_event_group_id) {
			setLoading(false);
			return;
		}

		const { data, error } = await listEvents(filter);

		if (error) {
			showErrorToast('Não foi possível buscar os eventos.');
		} else {
			setEvents(data);
		}
		setLoading(false);
	}, [answers?.insurance?.id, answers?.event_group?.id]);

	const getCalendarAndSlots = useCallback(
		async (selectedEvent, selectedPlace) => {
			const filter = {
				event_id: selectedEvent?.id,
				place_id: selectedPlace?.id,
				insurance_id: answers?.insurance?.id,
				patient_id: patient?.id
			};

			if (!filter.event_id || !filter.place_id) return;

			setLoadingTimegrid(true);
			const { data, error } = await list(filter);

			if (error) {
				showErrorToast('Não foi possível buscar os horários disponíveis.');
			}

			const { datesEnabled, currentDateSlotsByUser, closestSelectedDate } = (data || []).reduce(
				(acc, info) => {
					if (DateUtils.isToday(info.date)) return acc;
					if (!acc.closestSelectedDate && info.status === 'AVAILABLE') {
						acc.closestSelectedDate = info.date;
						acc.currentDateSlotsByUser = info.slotsByUser;
					}
					if (info.status === 'AVAILABLE') {
						acc.datesEnabled.push(info.date);
					}
					return acc;
				},
				{
					datesEnabled: [],
					currentDateSlotsByUser: {},
					closestSelectedDate: null
				}
			);

			setSlotsByDate(data);
			setEnabledDates(datesEnabled);
			setCurrentSlotsByUser(currentDateSlotsByUser);
			setSelectedDate(closestSelectedDate || moment().format('YYYY-MM-DD'));
			setLoadingTimegrid(false);
		},
		[answers?.insurance?.id, patient?.id]
	);

	useEffect(() => {
		getEvents();
	}, [getEvents]);

	useEffect(() => {
		if (answers?.event && answers?.place) {
			getCalendarAndSlots(answers?.event, answers?.place);
		}
	}, [answers?.event, answers?.place, getCalendarAndSlots]);

	const handleSubmit = useCallback(async () => {
		const { event, place, insurance, slot } = answers;

		if (!event || !place || !patient || !slot) {
			showErrorToast('Por favor, complete todos os campos.');
			return;
		}

		const start = moment(slot.start, 'HH:mm');
		const end = moment(slot.end, 'HH:mm');

		const attendanceData = {
			event_id: event.id,
			place_id: place.id,
			patient_id: patient.id,
			insurance_id: insurance?.id,
			start_date: moment(selectedDate).hour(start.format('HH')).minute(start.format('mm')),
			end_date: moment(selectedDate).hour(end.format('HH')).minute(end.format('mm')),
			user_id: slot.user_id
		};

		if (!patient.id) {
			appendAnswers({
				slot: slot,
				start_date: attendanceData.start_date,
				end_date: attendanceData.end_date
			});
			return NavigationUtils.navigate(history, '/patient-form');
		}

		setLoading(true);
		const { data, error } = await createAttendance(attendanceData);
		setLoading(false);

		if (error) {
			error.code === 'ATTENDANCE_LIMIT_REACHED'
				? showErrorToast('Limite de agendamentos para esse CPF atingido. Tente novamente em outra data.')
				: showErrorToast('Algo de errado aconteceu ao criar o agendamento.');
		} else {
			appendAnswers({ external_pages_token: data.external_pages_token });

			NavigationUtils.navigate(history, `/scheduled-succesfully?token=${data.external_pages_token}`);
		}
	}, [answers, selectedDate, appendAnswers, history, patient]);

	const handleSelectDate = useCallback(
		(date) => {
			setSelectedDate(date);
			const dateInfoSlots = slotsByDate.find((item) => item.date === date);
			setCurrentSlotsByUser(dateInfoSlots?.slotsByUser || []);
		},
		[slotsByDate]
	);

	const handleNavigateBackPress = useCallback(() => {
		clearEventSlotPlaceAndUser();
		NavigationUtils.navigate(history, '/select-event-group-payment-method');
	}, [clearEventSlotPlaceAndUser, history]);

	const handleSelectEvent = useCallback(
		async (eventId) => {
			const selectedEvent = scheduleSettings.events.find((item) => item.id === ~~eventId);
			setAnswer('event', selectedEvent);
		},
		[scheduleSettings.events, setAnswer]
	);

	const handleSelectPlace = useCallback(
		async (placeId) => {
			const selectedPlace = scheduleSettings.places.find((item) => item.id === ~~placeId);
			setAnswer('place', selectedPlace);
		},
		[scheduleSettings?.places, setAnswer]
	);

	return (
		<SelectTimegridForm
			loading={loading}
			loadingTimegrid={loadingTimegrid}
			selectedSlot={answers?.slot}
			listMaxHeight={listMaxHeight}
			selectedDate={selectedDate}
			variant={settings?.theme?.name}
			enabledDates={enabledDates}
			onSelectDate={handleSelectDate}
			currentSlotsByUser={currentSlotsByUser}
			onSubmitPress={handleSubmit}
			onSelectSlot={(slot) => setAnswer('slot', slot)}
			handleNavigateBackPress={handleNavigateBackPress}
			submitText={patient?.id ? 'Realizar agendamento' : 'Próximo'}
			patient={patient}
			selectedEventId={answers?.event?.id}
			onSelectEvent={handleSelectEvent}
			events={events}
			selectedPlaceId={answers?.place?.id}
			onSelectPlace={handleSelectPlace}
			places={scheduleSettings?.places}
			selectedPlace={answers?.place}
		/>
	);
}

export default SelectTimegridFormContainer;
